.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main {
    flex-grow: 1;
    padding: 0 24px 0 24px;
    @media screen and (min-width: 767px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (min-width: 992px) {
      padding-left: 136px;
      padding-right: 136px;
    }
  }
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -142px; /* the bottom margin is the negative value of the footer's height */
}

.wrong-network {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
}
