.wallet_detail_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  * {
    outline: none;
  }
  .wallet_detail_wrapper {
    min-width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fafafb;
    border-radius: 24px;
    padding: 32px;
    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #1e1e1e;
      margin-bottom: 24px;
    }
    .network-wrapper {
      align-items: center;
      display: flex;
      width: 100%;
      .network-desc {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #828282;
      }
      .change-network-btn {
        margin-left: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 8px;
        width: 57px;
        height: 21px;
        left: 242px;
        top: 53px;
        background: #98b1f0;
        border-radius: 6px;
        span {
          width: 41px;
          height: 15px;
          left: 8px;
          top: 3px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          align-items: center;
          color: #ffffff;
        }
      }
    }

    .wallet-content-bg {
      padding: 32px 16px;
      background: #eff4ff;
      border-radius: 24px;
      display: flex;
      width: 100%;
      margin-top: 25px;
      flex-direction: column;

      .address {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #fd6f3b;
        margin-bottom: 10px;
      }
      .copy-address > div{
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #98b1f0;
        margin-right: 25px;
        cursor: pointer;
      }
      .view-address {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-decoration-line: underline;
        color: #98b1f0;
        cursor: pointer;
      }
    }

    .button-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      justify-content: center;
      margin-top: 20px;

      .disconnect {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 37.5px;
        padding-right: 37.5px;
        height: 40px;
        color: #fd6f3b;
        background: transparent;
        border-radius: 24px;
        border: 0.5px solid #fd6f3b;
        cursor: pointer;
      }
    }
  }
}
