.header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.12));
  background: #fafafb;
  overflow-x: hidden;
  overflow: visible;

  * {
    outline: none;
  }

  @media screen and (min-width: 767px) {
    padding-left: 60px;
    padding-right: 60px;
    // overflow-y: hidden;
  }

  @media screen and (min-width: 992px) {
    padding-left: 136px;
    padding-right: 136px;
  }

  .left_panel {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    cursor: pointer;
    margin-right: 16px;
  }
  .menu_panel {
    @media screen and (max-width: 768px) {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 64px;
      background: white;
      flex-direction: column;
      filter: drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.12));
    }

    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    .menu_text {
      color: #000000;
      cursor: pointer;
      text-decoration: none;

      @media screen and (max-width: 768px) {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .menu_text:hover {
      color: #f9b93b;
    }
    .menu_text_selection {
      color: #f9b93b;
    }
  }
  .right_panel {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    .connect_btn {
      margin-left: auto;
      padding: 0 29px 0 29px;
      height: 40px;
      left: 1128px;
      background: #fd6f3b;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      margin-left: 16px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .btn-bugger {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.submenu {
  position: relative;
  padding: 10px;

  &:hover {
    .menu-dropdown {
      display: block;
    }
  }

  .menu-dropdown {
    display: none;
    position: absolute;
    margin-top: 10px;
    z-index: 999;
    background: #fff;
    width: 150px;

    .menu-dropdown-item {
      padding: .75rem 1rem;
      border-bottom: 1px solid #eee;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-right: 36px;
  }
}
