.wrong-network-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  * {
    outline: none;
  }
  .wrong-network-wrapper {
    min-width: 435px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fafafb;
    border-radius: 24px;
    padding: 32px;
    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #1e1e1e;
      margin-bottom: 24px;
    }
    .warning-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #828282;
    }
  }
}
