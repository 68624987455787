* {
  margin: 0;
}
html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Oswald'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Oswald Bold';
  src: local('Oswald Bold'), url(./assets/fonts/Oswald-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./assets/fonts/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: local('Oswald Bold'), url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Oswald'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}

.text-alert-error {
  font-size: 0.85rem;
  font-weight: normal;
  color: rgb(211, 58, 58);
  width: 100%;
  font-family: 'Montserrat';
  src: local('Oswald'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
.text-alert-info {
  font-size: 0.85rem;
  font-weight: normal;
  color: rgb(46, 144, 161);
  width: 100%;
  font-family: 'Montserrat';
  src: local('Oswald'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
.text-alert-success {
  font-size: 0.85rem;
  font-weight: normal;
  color: rgb(67, 185, 72);
  width: 100%;
  font-family: 'Montserrat';
  src: local('Oswald'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
