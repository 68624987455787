.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #000000;
  padding-left: 135px;
  padding-right: 135px;
  font-family: Montserrat;
  padding: 16px;
  margin-bottom: -18px;

  @media screen and (min-width: 767px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 136px;
    padding-right: 136px;
  }

  .left {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #ffffff;
  }

  .right {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .community {
      height: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: #ffffff;
      .title {
        margin-bottom: 16px;
      }
      .logo {
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
    .link {
      cursor: pointer;
    }
    .link:hover {
      color: #f9b93b;
    }

    .legal {
      margin-right: 83px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: #ffffff;
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 4px;
      }
      .detail {
        a {
          color: #ffffff;
          text-decoration: none;
        }
        font-size: 12px;
        line-height: 18px;
        margin-top: 4px;
      }
    }
  }
}
