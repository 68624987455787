.connect_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  * { outline: none };
  .connectors_wrapper {
    padding: 24px 32px 52px 24px;
    background: #FAFAFB;
    border-radius: 24px;
    width: 510px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .title {
      width: 100%;
      color: #000000;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 30px;
    }
    .connectors {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 90px;
      background: #EFF4FF;
      border-radius: 24px;
      .connector-name {
        margin-left: 32px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #FD6F3B;
      }
      .icon {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 32px;
      }
    }
    .connectors:hover {
      background: #d2d6e0;
    }
  }
}